import React, { Component } from 'react';

class LoginError extends Component<{message: string}> {

	render() {
		return <div className="loginError">
			<p>An error has occurred while logging in:</p>
			<p>{this.props.message}</p>
			<p>Reload the page and try again</p>
		</div>;
	}

}

export default LoginError;