import React, { Component } from 'react';

import { API, dev } from '../../api/API';
import { LocalSettings } from '../../settings/LocalSettings';
import { BooruNames, RatingShortNames } from '../../api/Data';
import { DisplayPost } from '../DisplayPost';
import { ImageHash } from '../group/ImageHash';

import './Preview.css';
import {isVideo, Video} from "../viewer/Video";

interface PreviewProps {
	post: DisplayPost;
	viewingSaved: boolean;
	selected: boolean;
	groupSize: number;
	hasUnseenInGroup: boolean;
	onClick: () => void;
	onGroupClick: () => void;
	onHashAvailable: (hash: ImageHash) => void;
}

class Preview extends Component<PreviewProps> {

	render() {
		const post = this.props.post;

		const url = API.proxy(post.post.previewUrl);

		let className = 'preview';

		if (post.post.seen && !this.props.viewingSaved && !this.props.hasUnseenInGroup) {
			className += ' seen';

			if (LocalSettings.minimizeSeenPosts() && !post.doNotMinimize) {
				className += ' minimized';
			}
		}

		if (post.post.saved) {
			className += ' saved';
		}

		return <div id={post.post.booru + '/' + post.post.id} className={'previewWrapper' + (this.props.selected ? ' selected' : '')}>
			<div className={className}>
				{this.props.groupSize > 1 ? <button className="groupSize" onClick={() => this.props.onGroupClick()}>{this.props.groupSize}</button> : null}

				<div className={this.getBackgroundClass()} style={this.getBackgroundStyle(url)}/>

				<div className="previewImage">
					<div onClick={e => this.onClick(e)}>
						{isVideo(url) ?
							<Video url={url} controls={false} muted={true}/> :
							<img src={url} alt={"Preview"} crossOrigin={dev ? 'anonymous' : ''} onLoad={e => this.onLoad(e.target)}/>}
					</div>
				</div>

				<div className="previewInfo">
					{BooruNames[post.post.booru]}
					{' | '}
					<span className={'rating ' + post.post.rating.toLowerCase()}>{RatingShortNames[post.post.rating]}</span>
					{' | S: '}
					<span className={'score ' + (post.post.score < 0 ? 'negative' : '')}>{post.post.score}</span>
				</div>
			</div>
		</div>;
	}

	private getBackgroundClass(): string {
		return "previewBackground " + (LocalSettings.isBlur() ? "blur" : "gray");
	}

	private getBackgroundStyle(url: string): {backgroundImage?: string} {
		return LocalSettings.isBlur() ? {backgroundImage: 'url(' + url + ')'} : {};
	}

	private onLoad(image: any): void {
		if (this.props.post.hash !== null) {
			this.props.onHashAvailable(this.props.post.hash);
			return;
		}

		try {
			this.props.onHashAvailable(new ImageHash(image));
		} catch (e) {
			console.error('Failed to create hash for', image, e);
		}
	}

	private onClick(e: any) {
		e.preventDefault();
		e.stopPropagation();
		this.props.onClick();
	}

}

export default Preview;
