import axios, { AxiosPromise } from 'axios';

import querystring from 'query-string';

import { TokenStorage } from './TokenStorage';

// Dev switch

export const dev = window.location.host.startsWith('localhost');

const metabooruHost = 'https://metabooru.me/';

const host = dev ? 'http://127.0.0.1/' : metabooruHost;

// Type definitions

export type Rating = 'EXPLICIT' | 'QUESTIONABLE' | 'SAFE';

export type BooruType = 'E621' |
		'RULE_34_XXX' |
		'SANKAKU_COMPLEX' |
		'DERPIBOORU' |
		'FURBOORU' |
		'GELBOORU' |
		'PBOORU' |
		'PONYBOORU' |
		'DANBOORU' |
		'FURRY_BOORU' |
		'YANDERE' |
		'XBOORU' |
		'REALBOORU' |
		'IDOL_SANKAKU_COMPLEX';

export type GenderFilterMode = 'UNSPECIFIED' | 'ONLY_WITH_HINTING_TAGS' | 'NO_HINTING_TAGS';

export interface Post {
	booru: BooruType;
	id: number;
	previewUrl: string;
	postUrl: string;
	rating: Rating;
	score: number;
	tags: string[];
	customTags: string[];
	seen: boolean;
	saved: boolean;
	originalImageUrl?: string;
}

export type LoginResult = {error?: string, token?: string};

export interface PostKey {
	booru: BooruType;
	id: number;
}

export interface SettingsMap {
	[key: string]: string | object;
}

// Method definitions

export class API {

	// Auth

	static login(login: string, password: string, callback: APICallback<LoginResult>) {
		apiCall(true, 'api/login', {login: login, password: password}, callback);
	}

	static validateToken(token: string, callback: APICallback<{valid: boolean}>) {
		apiCall(false, 'api/validateToken', {token: token}, callback);
	}

	// Search

	static nextPage(
		sessionId: string,
		query: string,
		skipWhileSeen: boolean,
		expectedPage: number,
		genderFilterMode: GenderFilterMode,
		callback: APICallback<{pages: Array<{page: number, posts: Post[]}>, hasNextPage: boolean}>) {
		apiCall(true, 'api/nextPage', {
			sessionId: sessionId,
			query: query,
			skipWhileSeen: skipWhileSeen ? 1 : 0,
			expectedPage: expectedPage,
			genderFilterMode: genderFilterMode
		}, callback);
	}

	static closeSearchSession(sessionId: string, callback: APICallback<{}>) {
		apiCall(true, 'api/closeSearchSession', {sessionId: sessionId}, callback);
	}

	static setSeenStatus(posts: PostKey[], seen: boolean, callback: APICallback<{}>) {
		apiCall(true, 'api/setSeenStatus', {posts: posts.map(x => x.booru + '/' + x.id).join(','), seen: seen ? 1 : 0}, callback);
	}

	// Posts

	static getFullPost(booru: BooruType, postId: number, callback: APICallback<{originalImageUrl: string}>) {
		apiCall(false, 'api/getFullPost', {booru: booru, postId: postId}, callback);
	}

	static savePost(post: Post, callback: APICallback<{}>) {
		apiCall(true, 'api/savePost', {postJson: JSON.stringify(post)}, callback);
	}

	static deleteSavedPost(booru: BooruType, postId: number, callback: APICallback<{}>) {
		apiCall(true, 'api/deleteSavedPost', {booru: booru, postId: postId}, callback);
	}

	static addCustomTag(booru: BooruType, postId: number, tag: string, callback: APICallback<{}>) {
		apiCall(true, 'api/addCustomTag', {booru: booru, postId: postId, tag: tag}, callback);
	}

	static removeCustomTag(booru: BooruType, postId: number, tag: string, callback: APICallback<{}>) {
		apiCall(true, 'api/removeCustomTag', {booru: booru, postId: postId, tag: tag}, callback);
	}

	// Settings

	static getUserSettings(callback: APICallback<SettingsMap>) {
		apiCall(false, 'api/settings/getAll', {}, callback);
	}

	static setUserSetting(key: string, value: string, callback: APICallback<{}>) {
		apiCall(true, 'api/settings/setValue', {key: key, value: value}, callback);
	}

	static addValueToUserSetting(key: string, element: string, callback: APICallback<{}>) {
		apiCall(true, 'api/settings/addToList', {key: key, element: element}, callback);
	}

	static setValueInUserSetting(key: string, objectKey: string, value: string, callback: APICallback<{}>) {
		apiCall(true, 'api/settings/setValueInObject', {key: key, objectKey: objectKey, value: value}, callback);
	}

	// Util

	static proxy(url: string): string {
		// Костылим добавление токена в URL сохранёнок, потому что они отдаются основным бекендом, который просит токен.
		// Если бы токен передавался в куки, такой костыль бы не понадобился...
		const encryptedFileMethod = 'api/downloadEncryptedFile?';

		if (url.startsWith(host + encryptedFileMethod) || url.startsWith(metabooruHost + encryptedFileMethod)) {
			const token = TokenStorage.getToken();

			// Запрос должен проходить через локальный nginx для выставления правильных CORS заголовков.
			url = url.replace(metabooruHost, host);

			return url + (token === undefined ? '' : '&token=' + encodeURIComponent(token));
		}

		// Не проксируем собственные ресурсы.
		if (url.startsWith(host)) {
			return url;
		}

		return host + 'proxyUrl/' + getFileNameFromUrl(url) + '?url=' + encodeURIComponent(url);
	}

	// Отключает кнопку евента, делает запрос, при ошибке делает alert(errorMessage)
	static doRequestAlertError<T>(event: any, method: (c: APICallback<T>) => void, callback: (data: T) => void): void {
		const button = event.target;

		button.disabled = true;
		
		method(response => {
			button.disabled = false;

			if (response.isError()) {
				alert(response.error!.errorMessage!);
			} else {
				callback(response.response!);
			}
		});
	}

}

// Implementation

export interface APIError {

	errorMessage: string;

}

class APIResponse<T> {

	error?: APIError;
	response?: T;

	constructor(error?: APIError, response?: T) {
		this.error = error;
		this.response = response;
	}

	isError() {
		return this.error !== undefined;
	}

}

class CallbackError extends Error {

	cause: Error;

	constructor(cause: Error) {
		super();
		this.cause = cause;
	}

}

export type APICallback<T> = (response: APIResponse<T>) => void;

function apiCall(isPost: boolean, method: string, params: {[key: string]: any}, callback: APICallback<any>) {
	let promise: AxiosPromise;

	if (method !== 'api/login' && method !== 'api/validateToken') {
		params.token = TokenStorage.getToken();
	}

	const paramString = querystring.stringify(params);

	if (isPost) {
		promise = axios.post(host + method, paramString === '' ? undefined : paramString, {withCredentials: !dev, headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
	} else {
		promise = axios.get(host + method + (paramString === '' ? '' : '?' + paramString), {withCredentials: !dev});
	}

	promise.then(response => {
		try {
			callback(new APIResponse(undefined, response.data));
		} catch (e) {
			throw new CallbackError(e);
		}
	}).catch(e => {
		if (e instanceof CallbackError) {
			throw e.cause;
		}
		
		const error = {errorMessage: method + ': ' + (e.response === undefined ? 'Network error' : e.response.data.errorMessage)};

		callback(new APIResponse(error, undefined));
	});
}

function getFileNameFromUrl(url: string) {
	let file = url;
		
	let i = file.indexOf('#');
	if (i !== -1) {
		file = file.substring(0, i);
	}
	
	i = file.indexOf('?');
	if (i !== -1) {
		file = file.substring(0, i);
	}

	i = file.lastIndexOf('/');
	if (i !== -1) {
		file = file.substring(i + 1);
	}

	return file;
}