import { SettingsMap, API, GenderFilterMode } from "../api/API";

export class Settings {

	private static map = {} as SettingsMap;

	static onLoad(s: SettingsMap): void {
		Settings.map = s;
	}

	static getLastQuery(): string {
		return Settings.get('query', '');
	}

	static setLastQuery(query: string): void {
		Settings.set('query', query);
	}

	static getQueryHistory(): string[] {
		const list = Settings.map.queryHistory;

		return typeof list === 'object' ? list as string[] : [];
	}

	static addQueryToHistory(query: string): void {
		const list = Settings.map.queryHistory as any;

		if (typeof list !== 'object') {
			Settings.map.queryHistory = [query];
		} else {
			list.push(query);
		}

		API.addValueToUserSetting('queryHistory', query, () => {});
	}

	static isFilterEnabled(filter: string): boolean {
		const flags = Settings.map.enabledFlags as any;

		return typeof flags === 'object' && (flags[filter] === true || flags[filter] === 'true');
	}

	static setFilterEnabled(filter: string, enabled: boolean): void {
		let flags = Settings.map.enabledFlags as any;
		
		if (typeof flags !== 'object') {
			Settings.map.enabledFlags = flags = {};
		}

		const value = enabled ? 'true' : 'false';

		flags[filter] = value;
		
		API.setValueInUserSetting('enabledFlags', filter, value, () => {});
	}

	static isPostControlsShown(): boolean {
		return Settings.get('tagsShown', 'false') === 'true';
	}

	static setPostControlsShown(value: boolean): void {
		Settings.set('tagsShown', value ? 'true' : 'false');
	}

	static getGenderFilterMode(): GenderFilterMode {
		return Settings.get('genderFilterMode', 'UNSPECIFIED') as GenderFilterMode;
	}

	static setGenderFilterMode(mode: GenderFilterMode): void {
		Settings.set('genderFilterMode', mode);
	}

	static enableRealContent(): boolean {
		return Settings.get('enableRealContent', 'false') === 'true';
	}

	static setEnableRealContent(value: boolean): void {
		Settings.set('enableRealContent', value ? 'true' : 'false');
	}

	static getCustomBlacklist(): string {
		return Settings.get('customBlacklist', '');
	}

	static setCustomBlacklist(value: string): void {
		Settings.set('customBlacklist', value);
	}

	// ---

	private static get(key: string, def: string): string {
		const val = Settings.map[key];
		
		return typeof val === 'string' ? val : def;
	}

	private static set(key: string, value: string): void {
		Settings.map[key] = value;

		API.setUserSetting(key,value, () => {});
	}

}