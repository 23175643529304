export class Updater {

	private static updater = null as (() => void) | null;

	static setUpdater(updater: (() => void) | null): void {
		Updater.updater = updater;
	}

	static update(): void {
		if (Updater.updater !== null) {
			console.log('Updating app');
			
			Updater.updater();
		}
	}

}