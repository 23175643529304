import React, { Component } from 'react';

import { API } from '../../api/API';
import { BooruNames, RatingShortNames } from '../../api/Data';
import { Settings } from '../../settings/Settings';
import { Updater } from '../Updater';

import { DisplayPost } from '../DisplayPost';
import { Content } from './Content';

import './PostViewer.css';

const Tag = (props: {tag: string}) => <a className="tag" target="_blank" href={"index.html?query=" + encodeURIComponent(props.tag)} rel="noopener noreferrer">{props.tag}</a>;

interface PostViewerProps {
	post: DisplayPost;
	onClose: () => void;
}

const originalImageUrlCache = {} as {[key: string]: string};

class PostViewer extends Component<PostViewerProps> {

	state = {
		url: '',
		customTag: ''
	}

	componentDidMount() {
		this.loadPost(this.props);
	}

	componentWillReceiveProps(newProps: PostViewerProps) {
		this.setState({url: ''});
		this.loadPost(newProps);
	}

	render() {
		let controls = [];

		if (Settings.isPostControlsShown()) {
			const post = this.props.post.post;

			controls.push(
				<div key={0}>Booru: {BooruNames[post.booru]}</div>,
				<div key={1}>Score: {post.score}</div>,
				<div key={2}>Rating: {RatingShortNames[post.rating]}</div>,
				<div key={3}>Tag count: {post.tags.length}</div>,
				<div key={4}><a href={post.postUrl} target="_blank" rel="noopener noreferrer">Post link</a></div>,
				<div key={5}><a href={this.state.url} target="_blank" rel="noopener noreferrer">Content link</a></div>,
				<hr key={6}/>
			);

			if (post.saved) {
				controls.push(<div key={controls.length}><button onClick={e => this.deleteFromSaved(e)}>Delete from saved</button></div>);
			} else {
				controls.push(<div key={controls.length}><button onClick={e => this.save(e)}>Save post</button></div>);
			}

			controls.push(<hr key={controls.length}/>);

			if (post.saved) {
				controls.push(<div key={controls.length} className="customTagAdd">
					<input placeholder="Custom tag" value={this.state.customTag} onChange={e => this.setState({customTag: e.target.value})}/>

					<button onClick={e => this.addCustomTag(e)}>+</button>
				</div>);
			}

			for (const tag of post.customTags) {
				controls.push(<div key={controls.length} className="customTag">
					<button onClick={e => this.removeCustomTag(e, tag)}>-</button>

					<Tag tag={tag}/>
				</div>)
			}

			for (const tag of post.tags) {
				controls.push(<div key={controls.length}><Tag tag={tag}/></div>);
			}
		}

		// Пост будет закрыт при клике на картинку. Так и задумано; эта фича нужна Вовке для удобства на телефоне.
		return <div className="overlay">
			<div className={'postControls ' + (Settings.isPostControlsShown() ? 'shown' : '')}>{controls}</div>

			<div className="postContent">
				<button className="closeButton" onClick={() => this.props.onClose()}/>
				<button className={Settings.isPostControlsShown() ? 'tagHideButton' : 'tagShowButton'} onClick={() => this.toggleControls()}/>

				<div className="postContentWrapper" onClick={() => this.props.onClose()}>
					{this.state.url !== '' ? <Content url={API.proxy(this.state.url)}/> : 'Loading post...'}
				</div>
			</div>
		</div>
	}

	private loadPost(props: PostViewerProps): void {
		const post = props.post.post;

		if (post.originalImageUrl !== undefined) {
			// Бекенд отдал ссылку напрямую
			this.setState({url: post.originalImageUrl});
			return;
		}

		const key = post.booru + '/' + post.id;
		const cached = originalImageUrlCache[key];

		if (cached !== undefined) {
			this.setState({url: cached});
			return;
		}

		// Получаем ссылку на пост
		API.getFullPost(post.booru, post.id, response => {
			if (!response.isError()) {
				originalImageUrlCache[key] = response.response!.originalImageUrl;
			}

			// Обрабатываем ответ, только если свойства не обновились
			if (this.props.post === props.post) {
				if (response.isError()) {
					alert(response.error!.errorMessage!);
					this.props.onClose();
					return;
				}

				this.setState({url: response.response!.originalImageUrl});
			}
		});
	}

	private save(e: any): void {
		const post = this.props.post.post;

		API.doRequestAlertError(e, c => API.savePost(post, c), () => {
			this.props.post.post.saved = true;

			this.props.post.deletedFromSaved = false;

			Updater.update();
		});
	}

	private deleteFromSaved(e: any): void {
		const post = this.props.post.post;

		API.doRequestAlertError(e, c => API.deleteSavedPost(post.booru, post.id, c), () => {
			this.props.post.deletedFromSaved = true;
			this.props.onClose();
		});
	}

	private toggleControls(): void {
		Settings.setPostControlsShown(!Settings.isPostControlsShown());

		Updater.update();
	}

	private addCustomTag(e: any): void {
		const post = this.props.post.post;
		const tag = this.state.customTag;

		if (post.customTags.indexOf(tag) !== -1) {
			// Already added
			return;
		}

		API.doRequestAlertError(e, c => API.addCustomTag(post.booru, post.id, tag, c), () => {
			post.customTags.push(tag);

			Updater.update();
		});
	}

	private removeCustomTag(e: any, tag: string): void {
		const post = this.props.post.post;

		if (post.customTags.indexOf(tag) === -1) {
			// Already removed
			return;
		}

		API.doRequestAlertError(e, c => API.removeCustomTag(post.booru, post.id, tag, c), () => {
			post.customTags.splice(post.customTags.indexOf(tag), 1);

			Updater.update();
		});
	}

}

export default PostViewer;
