import React, { Component } from 'react';

import { Filters } from '../../api/Data';
import { Settings } from '../../settings/Settings';
import { LocalSettings, updateZoomInHTML } from '../../settings/LocalSettings';
import { Updater } from '../Updater';

import BlacklistEditor from "./BlacklistEditor";

import './Menu.css';

function Checkbox(props: {checked: boolean, onChange: (value: boolean) => void}) {
	return <input type="checkbox" defaultChecked={props.checked} onClick={e => props.onChange((e.target as HTMLInputElement).checked)}/>;
}

class Menu extends Component<{onClose: () => void}> {

	render() {
		return <div className="menu">
			<div>
				<button className="close" onClick={e => {e.preventDefault(); this.props.onClose()}}>Close menu</button>
			</div>

			<h2>Filters</h2>

			<ul className="filters">
				{Filters.map(filter => <li key={filter.id}>
					<Checkbox checked={Settings.isFilterEnabled(filter.id)} onChange={x => {Settings.setFilterEnabled(filter.id, x); Updater.update();}}/>
					<label>{filter.name}</label>
				</li>)}
			</ul>

			<BlacklistEditor/>

			<h2>Content settings</h2>

			<div>
				<Checkbox checked={Settings.enableRealContent()} onChange={x => {Settings.setEnableRealContent(x); Updater.update();}}/>
				<label>Enable real content</label>
			</div>

			<h2>Gender settings</h2>

			<div>
				<Checkbox
					checked={Settings.getGenderFilterMode() === 'ONLY_WITH_HINTING_TAGS'}
					onChange={x => {Settings.setGenderFilterMode(x ? 'ONLY_WITH_HINTING_TAGS' : 'UNSPECIFIED'); Updater.update();}}
				/>
				<label>No badly tagged</label>
			</div>

			<div>
				<Checkbox
					checked={Settings.getGenderFilterMode() === 'NO_HINTING_TAGS'}
					onChange={x => {Settings.setGenderFilterMode(x ? 'NO_HINTING_TAGS' : 'UNSPECIFIED'); Updater.update();}}
				/>
				<label>Only badly tagged</label>
			</div>

			<h2>Local settings</h2>

			<div>
				<Checkbox checked={LocalSettings.isBlur()} onChange={x => {LocalSettings.setBlur(x); Updater.update();}}/>
				<label>Blur backgrounds</label>
			</div>

			<div>
				<Checkbox checked={LocalSettings.minimizeSeenPosts()} onChange={x => {LocalSettings.setMinimizeSeenPosts(x); Updater.update();}}/>
				<label>Minimize seen posts</label>
			</div>

			<div className="pageScale">
				<label>Page scale</label>
				<select value={LocalSettings.getZoom()} onChange={e => this.onZoomChange(e)}>
					{[50, 75, 100, 125, 150, 175, 200].map(e => <option key={e} value={e}>{e + '%'}</option>)}
				</select>
			</div>

			<h2>Controls</h2>

			<div className="controlsHelp">
				<p><b>T</b>: toggle the side panel of the post.</p>
				<p><b>Q</b>: go to the previous page.</p>
				<p><b>E</b>: go to the next page.</p>
				<p><b>WASD</b>: choose selected post.</p>
				<p><b>Space</b>: open or close the post.</p>
				<p><b>Shift</b>: close the post.</p>
				<hr/>
				<p><b>Swipe left</b>: previous post in the group.</p>
				<p><b>Swipe right</b>: next post in the group.</p>
			</div>
		</div>
	}

	private onZoomChange(e: any) {
		const value = +e.target.value;
		LocalSettings.setZoom(value);
		updateZoomInHTML();
		Updater.update();
	}

}

export default Menu;
