export class LocalSettings {

	static isBlur(): boolean {
		return !(localStorage.noBlur === 'true');
	}

	static setBlur(value: boolean): void {
		localStorage.noBlur = !value;
	}

	static minimizeSeenPosts(): boolean {
		return localStorage.minimizeSeen === 'true';
	}

	static setMinimizeSeenPosts(value: boolean): void {
		localStorage.minimizeSeen = value;
	}

	static getZoom(): number {
		const value = +(localStorage.zoom === undefined ? '100' : localStorage.zoom);

		return Number.isNaN(value) ? 100 : value;
	}

	static setZoom(value: number): void {
		localStorage.zoom = '' + value;
	}

}

export function updateZoomInHTML() {
	document.body.parentElement!.style.fontSize = (LocalSettings.getZoom() / 10) + 'px';
}
