import React, { Component } from 'react';

import querystring from 'query-string';

import { API } from '../api/API';
import { DisplayPost } from './DisplayPost';
import { updateZoomInHTML } from '../settings/LocalSettings';
import { Settings } from '../settings/Settings';
import { Filters, BooruNames } from '../api/Data';
import { Updater } from './Updater';
import { ImageHash } from './group/ImageHash';
import { GroupList } from './group/GroupList';
import { registerKeyListener } from './KeyListener';
import { registerTouchListener } from './TouchEventListener';

import Menu from './menu/Menu';
import Preview from './preview/Preview';
import PostViewer from './viewer/PostViewer';

import './MainPage.css';

class MainPage extends Component<{}> {

	state = {
		// Содержимое поля ввода запроса
		query: Settings.getLastQuery(),

		// Текущий запрос сессии
		sessionQuery: '',
		// id сессии
		sessionId: '',
		// Текущая страница, >= 1, если сессия существует, иначе 0
		page: 0,
		// Кеш посещённых страниц
		pageCache: {} as {[key: number]: DisplayPost[]},

		loading: false,
		showMenu: false,

		posts: [] as DisplayPost[],
		groups: new GroupList(),
		viewedPost: null as DisplayPost | null,
		viewedGroup: null as DisplayPost[] | null,
		// Номер последней пустой страницы, после которой гарантированно ничего нет
		lastEmptyPage: -1,
		// scrollTop контента до открытия группы
		prevScrollTop: -1,
		// Высота контента до открытия группы
		prevContentHeight: -1,

		// Ключ текущего выбранного поста
		selectedPost: null as string | null
	}

	private contentRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		Updater.setUpdater(() => this.setState({}));

		updateZoomInHTML();

		this.loadStateFromURL();

		this.registerKeyListener();
		this.registerTouchListener();

		// Евент кидается, когда пользователь нажал "Назад" в браузере.
		// В таком случае, loadStateFromURL загрузит запрос и открытый пост из нового URL.
		window.addEventListener("popstate", e => this.loadStateFromURL());
	}

	componentWillUnmount() {
		Updater.setUpdater(null);
	}

	render() {
		const viewingSaved = this.state.sessionQuery.indexOf('booru:saved') !== -1;

		// Render group viewer
		let groupViewer = null;

		if (this.state.viewedGroup !== null) {
			const posts = this.state.viewedGroup.filter(x => !viewingSaved || !x.deletedFromSaved).map(post => <div key={post.post.booru + post.post.id} className="postWrapper">
				<Preview
					post={post}
					viewingSaved={viewingSaved}
					selected={this.isSelected(post)}
					groupSize={1}
					hasUnseenInGroup={false}
					onClick={() => this.onPostClick(post)}
					onGroupClick={() => {}}
					onHashAvailable={hash => this.onHashAvailable(post, hash)}
				/>
			</div>);

			groupViewer = <div className="groupViewer" onClick={() => this.closeGroup()}>
				<button className="closeButton" onClick={() => this.closeGroup()}/>

				{posts}
			</div>;
		}

		// Render posts
		const posts = [];
		const groups = [] as DisplayPost[][];

		for (const post of this.state.posts) {
			if (post.deletedFromSaved) {
				continue;
			}

			const group = this.state.groups.getGroup(post);

			if (groups.findIndex(g => g === group) !== -1) {
				// Группа уже отображается
				continue;
			}

			groups.push(group);

			posts.push(<Preview
				key={post.post.booru + post.post.id}
				post={post}
				viewingSaved={viewingSaved}
				selected={this.isSelected(post)}
				groupSize={group.length}
				hasUnseenInGroup={group.findIndex(p => !p.post.seen) !== -1}
				onClick={() => this.onPostClick(post)}
				onGroupClick={() => this.onGroupClick(group)}
				onHashAvailable={hash => this.onHashAvailable(post, hash)}
			/>);
		}

		return <div className="main">
			{this.state.showMenu ? <Menu onClose={() => this.setState({showMenu: false})}/> : null}

			{this.state.viewedPost !== null ? <PostViewer
				post={this.state.viewedPost}
				onClose={() => this.onPostClose()}
			/> : null}

			<div className="search">
				<button className="menuButton" onClick={() => this.setState({showMenu: !this.state.showMenu})}>M</button>

				<input type="text" list="queryHistory" value={this.state.query} onChange={e => this.onQueryChange(e)} onKeyDown={e => this.onEnter(e)}/>

				<datalist id="queryHistory">{Settings.getQueryHistory().map((query, i) => <option key={i} value={query}/>)}</datalist>

				<button onClick={() => this.startSearch()} disabled={this.state.loading}>Search</button>
			</div>

			<div className="content" ref={this.contentRef}>
				{groupViewer !== null ? groupViewer : (posts.length > 0 ? posts : this.getStatusMessage())}
			</div>

			<div className="navigation">
				<button onClick={() => this.loadPrevPage()} disabled={this.state.loading}>{'<'}</button>
				<span>{this.state.page}</span>
				<button onClick={() => this.loadNextPage(false)} disabled={this.state.loading}>{'>'}</button>
				<button onClick={() => this.loadNextPage(true)} disabled={this.state.loading}>{'>>'}</button>
				<button onClick={() => this.setSeenStatus(this.state.posts, true)} disabled={this.state.loading}>All seen</button>
				<button onClick={() => this.setSeenStatus(this.state.posts, false)} disabled={this.state.loading}>All unseen</button>
			</div>
		</div>;
	}

	private getStatusMessage(): string {
		if (this.state.loading) {
			return 'Loading posts...';
		}

		if (this.state.sessionId === '') {
			return 'Press "Search"';
		}

		return this.state.page !== this.state.lastEmptyPage ? 'No posts on this page, try next' : 'No more posts for this query';
	}

	private loadStateFromURL() {
		if (window.location.search === '') {
			this.setState({viewedPost: null});
			return;
		}

		const parsed = querystring.parse(window.location.search.substring(1));

		if (parsed.post !== undefined) {
			const post = JSON.parse(parsed.post as string);

			this.setState({viewedPost: new DisplayPost(post)});
		} else {
			this.setState({viewedPost: null});
		}

		// Если доступен запрос, начинаем поиск автоматически; кроме случая, когда мы уже листаем посты по этому запросу.
		if (parsed.query !== undefined && parsed.query !== this.state.sessionQuery) {
			this.setState({query: parsed.query}, () => {
				Settings.setLastQuery(parsed.query as string);

				this.startSearch();
			});
		}
	}

	private onQueryChange(e: any): void {
		const value = e.target.value;
		this.setState({query: value});
		Settings.setLastQuery(value);
	}

	private onEnter(e: any): void {
		if (!this.state.loading && e.keyCode === 13) {
			this.startSearch();
		}
	}

	private updateDocumentTitle(): void {
		document.title = this.getDocumentTitle();
	}

	private getDocumentTitle(): string {
		const {query, viewedPost, loading} = this.state;

		let title: string;

		if (viewedPost !== null) {
			title = '#' + viewedPost.post.id + ' — ' + BooruNames[viewedPost.post.booru];
		} else if (query.trim() !== '') {
			title = query;
		} else {
			title = 'Metabooru';
		}

		if (loading) {
			title = '[L] ' + title;
		}

		return title;
	}

	private startSearch(): void {
		if (this.state.sessionId !== '') {
			API.closeSearchSession(this.state.sessionId, () => {});
		}

		let query = this.state.query;

		if (Settings.getQueryHistory().indexOf(query) === -1) {
			Settings.addQueryToHistory(query);
		}

		this.setState({sessionId: randomId(), sessionQuery: query, page: 1, pageCache: {}, lastEmptyPage: -1}, () => {
			this.putQueryToUrl();
			this.loadNextPage(false);
		});
	}

	private putQueryToUrl(): void {
		let params = window.location.search !== '' ? querystring.parse(window.location.search.substring(1)) : {};

		params.query = this.state.query;

		window.history.pushState('', '', '/?' + querystring.stringify(params));

		this.updateDocumentTitle();
	}

	private processQueryBeforeLoadingPage(query: string): string {
		// Чтобы не срало, не добавляем теги контента, если идёт поиск по вайтлисту буру
		if (!query.startsWith('booru:') && query.indexOf(' booru:') === -1) {
			query += Settings.enableRealContent() ? ' booru:realbooru booru:idol' : ' -booru:realbooru -booru:idol';
		}

		for (const filter of Filters) {
			if (Settings.isFilterEnabled(filter.id)) {
				query += ' ' + filter.tags;
			}
		}

		const blacklist = Settings.getCustomBlacklist();

		if (blacklist !== '') {
			query += ' ' + blacklist;
		}

		return query;
	}

	private loadNextPage(skipSeen: boolean): void {
		if (this.state.page === 0) {
			// Активной сессии нет.
			return;
		}

		if (this.state.page === this.state.lastEmptyPage) {
			// Дальше контента нет.
			return;
		}

		if (this.state.lastEmptyPage !== -1 && this.state.page === this.state.lastEmptyPage - 1) {
			// Разрешаем поглядеть на последнюю пустую страницу.
			this.setState({posts: [], page: this.state.lastEmptyPage});
			return;
		}

		if (!skipSeen) {
			const nextPage = this.state.page + 1;

			if (this.state.pageCache[nextPage] !== undefined) {
				this.setState({posts: this.state.pageCache[nextPage], page: nextPage});
				return;
			}
		}

		this.setState({loading: true}, () => this.updateDocumentTitle());

		const query = this.processQueryBeforeLoadingPage(this.state.sessionQuery);

		API.nextPage(this.state.sessionId, query, skipSeen, this.state.page, Settings.getGenderFilterMode(), response => {
			this.setState({loading: false}, () => this.updateDocumentTitle());

			if (response.isError()) {
				alert(response.error!.errorMessage!);
				return;
			}

			const pages = response.response!.pages;

			const newCache = Object.assign({}, this.state.pageCache);

			for (const page of pages) {
				newCache[page.page] = page.posts.map(x => new DisplayPost(x));
			}

			if (pages.length === 0) {
				return;
			}

			const last = pages[pages.length - 1];

			// Сброс скролла, чтобы в случае загрузки в неактивной вкладке новая страница отображалась сначала, а не с предыдущим смещением
			const content = this.contentRef.current;

			if (content !== null) {
				content.scrollTop = 0;
			}

			const posts = newCache[last.page];

			this.setState({
				page: last.page,
				pageCache: newCache,
				posts: posts,
				groups: new GroupList(),
				// Проверка, что номер будет действительно пустой страницы
				lastEmptyPage: response.response!.hasNextPage ? this.state.lastEmptyPage : last.page + (last.posts.length === 0 ? 0 : 1),
				selectedPost: this.selectPost(posts)
			});
		});
	}

	private loadPrevPage(): void {
		if (this.state.page === 1) {
			return;
		}

		const posts = this.state.pageCache[this.state.page - 1];

		this.setState({page: this.state.page - 1, posts: posts, groups: new GroupList(), selectedPost: this.selectPost(posts)});
	}

	private onPostClick(post: DisplayPost) {
		this.setSeenStatus([post], true);
		this.setState({viewedPost: post}, () => this.putPostToUrl());
	}

	private putPostToUrl() {
		let params = window.location.search !== '' ? querystring.parse(window.location.search.substring(1)) : {};

		const post = JSON.parse(JSON.stringify(this.state.viewedPost!.post));
		// Теги занимают слишком много места в URL.
		post.tags = [];
		// Эти поля не имеют смысла между разными пользователями.
		post.customTags = [];
		post.seen = false;
		post.saved = false;
		params.post = JSON.stringify(post);

		window.history.pushState('', '', '/?' + querystring.stringify(params));

		this.updateDocumentTitle();
	}

	private onPostClose() {
		this.setState({viewedPost: null}, () => this.removePostFromUrl());
	}

	private removePostFromUrl() {
		let params = window.location.search !== '' ? querystring.parse(window.location.search.substring(1)) : {};

		delete params.post;

		window.history.pushState('', '', '/?' + querystring.stringify(params));

		this.updateDocumentTitle();
	}

	private setSeenStatus(posts: DisplayPost[], seen: boolean): void {
		const changed = posts.filter(x => x.post.seen !== seen);

		if (changed.length > 0) {
			API.setSeenStatus(changed.map(x => x.post), seen, response => {
				if (response.isError()) {
					alert(response.error!.errorMessage);
				} else {
					for (const p of changed) {
						p.post.seen = seen;
						p.doNotMinimize = true;
					}

					Updater.update();
				}
			});
		}
	}

	private registerKeyListener() {
		registerKeyListener({
			getPosts: () => this.state.posts,
			getViewedPost: () => this.state.viewedPost,
			getContentDiv: () => this.contentRef.current,
			getSelectedPost: () => this.state.selectedPost,
			isSelected: (p: DisplayPost) => this.isSelected(p),
			loadPrevPage: () => this.loadPrevPage(),
			loadNextPage: (skipWhileSeen: boolean) => this.loadNextPage(skipWhileSeen),
			onPostClick: (p: DisplayPost) => this.onPostClick(p),
			closePost: () => this.setState({viewedPost: null}),
			selectPost: (key: string) => this.setState({selectedPost: key}),
			toSelectKey: (p: DisplayPost) => this.toSelectKey(p),
			togglePostSidePanel: () => {
				Settings.setPostControlsShown(!Settings.isPostControlsShown());

				Updater.update();
			}
		});
	}

	private registerTouchListener() {
		registerTouchListener(diff => {
			const post = this.state.viewedPost;

			if (post !== null) {
				const group = this.state.groups.getGroup(post);

				if (group !== undefined) {
					let nextIndex = (group.indexOf(post) + diff + group.length) % group.length;

					this.setState({viewedPost: group[nextIndex]});
				}
			}
		});
	}

	private onHashAvailable(post: DisplayPost, hash: ImageHash) {
		post.hash = hash;

		if (!this.state.groups.hasPost(post)) {
			this.setState({groups: this.state.groups.copyAndAdd(post)});
		}
	}

	private onGroupClick(group: DisplayPost[]): void {
		const div = this.contentRef.current;

		this.setState({viewedGroup: group, prevScrollTop: div !== null ? div.scrollTop : -1, prevContentHeight: div !== null ? div.scrollHeight : -1})
	}

	private closeGroup() {
		const top = this.state.prevScrollTop;
		const height = this.state.prevContentHeight;

		this.setState({viewedGroup: null}, () => {
			// При закрытии группы пытаемся восстановить позицию скролла. Так как после закрытия превью начнут грузиться заново,
			// выставить позицию сразу же нельзя, поэтому приходится ждать, пока всё прогрузится, то есть div контента станет такой же высоты, что до
			// открытия группы. У ожидания есть таймаут 3 сек.
			const div = this.contentRef.current;

			if (div === null || top === -1) {
				return;
			}

			let intervals = 0;
			let task: any;
			task = setInterval(() => {
				if (intervals++ > 3 * 20 || div.scrollHeight === height) {
					if (div.scrollHeight === height) {
						div.scrollTop = top;
					}

					clearInterval(task);

					return;
				}
			}, 50);
		});
	}

	private toSelectKey(post: DisplayPost): string {
		return post.post.booru + '/' + post.post.id;
	}

	private selectPost(posts: DisplayPost[]): string | null {
		return posts.length === 0 ? null : this.toSelectKey(posts[0]);
	}

	private isSelected(post: DisplayPost): boolean {
		return this.state.selectedPost === this.toSelectKey(post);
	}

}

export default MainPage;

function randomId() {
	return Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7);
}
