import React, { Component } from 'react';

import {Settings} from "../../settings/Settings";

import './BlacklistEditor.css';

class BlacklistEditor extends Component<{}> {

	state = {
		inputShown: false,
		value: ''
	}

	render() {
		if (!this.state.inputShown) {
			return <div className="blacklistEditor">
				Custom blacklist
				<button onClick={() => this.show()}>Edit</button>
			</div>;
		}

		return <div className="blacklistEditor">
			<div>
				Custom blacklist
				<button onClick={() => this.save()}>Save</button>
				<button onClick={() => this.hide()}>Cancel</button>
			</div>
			<textarea value={this.state.value} onChange={e => this.setState({value: e.target.value})}/>
		</div>;
	}

	private show(): void {
		this.setState({inputShown: true, value: Settings.getCustomBlacklist()});
	}

	private hide() {
		this.setState({inputShown: false});
	}

	private save(): void {
		let value = this.state.value
			.replace("\n", " ")
			.replace("\\s+", " ")
			.trim();

		Settings.setCustomBlacklist(value)

		this.hide();
	}

	private cancel(): void {
		this.hide();
	}

}

export default BlacklistEditor;
