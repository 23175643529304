import { DisplayPost } from "./DisplayPost";

export interface Callback {

	getPosts(): DisplayPost[];
	getViewedPost(): DisplayPost | null;
	getContentDiv(): HTMLDivElement | null;
	getSelectedPost(): string | null;
	isSelected(p: DisplayPost): boolean;
	toSelectKey(p: DisplayPost): string;

	loadPrevPage(): void;
	loadNextPage(skipWhileSeen: boolean): void;
	onPostClick(p: DisplayPost): void;
	closePost(): void;
	selectPost(key: string): void;
	togglePostSidePanel(): void;

}

export function registerKeyListener(callback: Callback) {
	document.addEventListener('keydown', (e: KeyboardEvent) => {
		const active = document.activeElement;

		if (active !== null && (active.nodeName === 'INPUT' || active.nodeName === 'TEXTAREA')) {
			// Эти компоненты сами реагируют на кнопки, не мешаем.
			return;
		}

		const code = e.keyCode;

		if (code === 84) {
			// T: Toggle side panel of the post.
			callback.togglePostSidePanel();
		}

		if (code === 81) {
			// Q: Prev page
			callback.loadPrevPage();
		}

		if (code === 69) {
			// E: Next page
			callback.loadNextPage(false);
		}

		if (code === 32) {
			// Space: Open or close post
			if (active !== null && active.nodeName === 'VIDEO') {
				// Пробел управляет воспроизведением видоса.
				return;
			}

			e.preventDefault();

			if (callback.getViewedPost() === null) {
				const post = callback.getPosts().find(p => callback.isSelected(p));

				if (post !== undefined) {
					callback.onPostClick(post);
				}
			} else {
				callback.closePost();
			}
		}

		if (code === 16) {
			// Shift: Close post
			if (callback.getViewedPost() !== null) {
				callback.closePost();
			}
		}

		if (code === 65 || code === 68 || code === 87 || code === 83) {
			// WASD: Navigation
			const posts = callback.getPosts();

			if (posts.length === 0) {
				return;
			}

			const select = (id: string) => {
				callback.selectPost(id);

				// Scroll to selected element
				const elem = document.getElementById(id);

				if (elem !== null) {
					const div = callback.getContentDiv();

					if (div !== null) {
						div.scrollTop = elem.offsetTop - 5;
					}
				}
			};

			const selectFirst = () => select(callback.toSelectKey(posts[0]));

			const selectedPost = callback.getSelectedPost();

			if (selectedPost === null) {
				selectFirst();
				return;
			}

			const index = posts.findIndex(p => callback.isSelected(p));

			if (index === -1) {
				selectFirst();
				return;
			}

			let next = null as string | null;

			if (code === 65) {
				// Left
				if (index === 0) {
					next = callback.toSelectKey(posts[posts.length - 1]);
				} else {
					next = callback.toSelectKey(posts[index - 1]);
				}
			} else if (code === 68) {
				// Right
				if (index === posts.length - 1) {
					next = callback.toSelectKey(posts[0]);
				} else {
					next = callback.toSelectKey(posts[index + 1]);
				}
			} else if (code === 87 || code === 83) {
				const elem = document.getElementById(selectedPost);

				if (elem === null) {
					return;
				}

				const x = (e: HTMLElement) => {
					const rect = e.getBoundingClientRect();
					return (rect.left + rect.right) / 2;
				};

				const top = (e: HTMLElement) => e.getBoundingClientRect().top;

				const dist = (x: number, y: number) => Math.abs(x - y);

				let found = null as HTMLElement | null;

				if (code === 87) {
					// Top

					// Выбрать пост, который:
					// 1. выше, но как можно ближе по y
					// 2. центр которого по x как можно ближе к центру

					for (const post of posts) {
						const key = callback.toSelectKey(post);

						if (key === selectedPost) {
							continue;
						}

						const other = document.getElementById(key);

						if (other === null) {
							continue;
						}

						if (top(other) >= top(elem)) {
							// Элемент не выше
							continue;
						}

						if (found === null || dist(x(elem), x(other)) < dist(x(elem), x(found)) || dist(top(elem), top(other)) < dist(top(elem), top(found))) {
							found = other;
						}
					}
				} else if (code === 83) {
					// Bottom

					// Выбрать пост, который:
					// 1. ниже, но как можно ближе по y
					// 2. центр которого по x как можно ближе к центру

					for (const post of posts) {
						const key = callback.toSelectKey(post);

						if (key === selectedPost) {
							continue;
						}

						const other = document.getElementById(key);

						if (other === null) {
							continue;
						}

						if (top(other) <= top(elem)) {
							// Элемент не ниже
							continue;
						}

						if (found === null || dist(x(elem), x(other)) < dist(x(elem), x(found)) || dist(top(elem), top(other)) < dist(top(elem), top(found))) {
							found = other;
						}
					}
				}

				if (found !== null) {
					next = found.id;
				}
			}

			if (next !== null) {
				select(next);
			}
		}
	}, false);
}
