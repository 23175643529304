import React, { Component } from 'react';

import { API } from './api/API';
import { TokenStorage } from './api/TokenStorage';
import { Settings } from './settings/Settings';

import LoginForm from './login/LoginForm';
import LoginError from './login/LoginError';
import MainPage from './main/MainPage';

import './App.css';

class App extends Component {

	state = {
		loading: false,
		status: '',
		valid: false,
		error: ''
	}

	componentDidMount() {
		const token = TokenStorage.getToken();

		if (token === undefined) {
			// Токена нет, отображается форма входа
			return;
		}

		this.setState({loading: true, status: 'Checking token'});

		API.validateToken(token, response => {
			this.setState({loading: false});

			if (response.isError()) {
				// Не удалось проверить токен
				this.setState({error: response.error!.errorMessage});
				return;
			}

			if (response.response!.valid) {
				// Токен валидный, грузим настройки
				this.loadSettings();
			} else {
				// Очистка невалидного токена
				TokenStorage.clearToken();
			}
		});
	}

	private loadSettings() {
		this.setState({loading: true, status: 'Loading settings'});

		API.getUserSettings(response => {
			this.setState({loading: false});

			if (response.isError()) {
				// Не удалось загрузить настройки
				this.setState({error: response.error!.errorMessage});
			} else {
				Settings.onLoad(response.response!);

				// Отображается главная страница
				this.setState({valid: true});
			}
		});
	}

	render() {
		if (this.state.loading) {
			return <div className="loading"><div>{this.state.status}...</div></div>;
		}

		if (this.state.valid) {
			return <MainPage/>;
		}

		if (this.state.error !== '') {
			return <LoginError message={this.state.error}/>;
		}

		return <LoginForm onLoggedIn={() => this.loadSettings()}/>;
	}

}

export default App;
