export interface Filter {
	id: string;
	name: string;
	tags: string
}

export const Filters = [
	{
		id: 'noMales',
		name: 'No males',
		tags: '-male -penis -dick -male* -1boy -2boys -3boys -4boys -?boy* -balls -yaoi -bara -shota -shotacon'
	},
	{
		id: 'noFemales',
		name: 'No females',
		tags: '-female -vagina -*female* -1girl -2girls -3girls -4girls -?girl* -breasts -pussy -vulva -femdom -yuri -loli -lolicon -filly -girl -vaginal* -cameltoe ' +
			'-medium_breasts -big_breasts -large_breasts -huge_breasts ' +
			'-cunnilingus -squirting -squirt -pussy_juice -lactation -multiple_girls -puffy_pussy -busty -gaping_vagina -hetero -cum_in_pussy -clitoris -*_clitoris -*_pussy -*_vagina ' +
			'-pregnant -pussy_* -clitoris_* -milf -breast_hold -breast_grab ' +
			'-uterus -*_uterus'
	},
	{
		id: 'noMonochrome',
		name: 'No monochrome',
		tags: '-monochrome -black_and_white'
	},
	{
		id: 'noScat',
		name: 'No scat',
		tags: '-scat -poop -poo -shit -shitting -shit_on* -feces -coprophagia -fart -farting -fart_fetish'
	},
	{
		id: 'noGore',
		name: 'No gore',
		tags: '-gore -guro -death -necrophilia -snuff -corpse'
	}
] as Filter[];

export const BooruNames = {
	E621: "e621.net",
	RULE_34_XXX: "rule34.xxx",
	SANKAKU_COMPLEX: "SankakuComplex",
	DERPIBOORU: "Derpibooru",
	FURBOORU: "Furbooru",
	GELBOORU: "Gelboooru",
	PBOORU: "Ponybooru",
	PONYBOORU: "Ponybooru",
	DANBOORU: "Danbooru",
	FURRY_BOORU: "FurryBooru",
	YANDERE: "yande.re",
	XBOORU: "XBooru",
	REALBOORU: "Realbooru",
	IDOL_SANKAKU_COMPLEX: "SC Idol"
};

export const RatingShortNames = {
	EXPLICIT: 'E',
	QUESTIONABLE: 'Q',
	SAFE: 'S'
};