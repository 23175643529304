// Размер картинки-хеша, которые будут сравниваться друг с другом
const size = 32;
		
export class ImageHash {

	private hash: ImageData;

	constructor(image: CanvasImageSource) {
		// У некоторых превью по краям бывают белые пиксели, поэтому края лучше обрезать
		const width = image.width as number - 1;
		const height = image.height as number - 1;

		const canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;

		const ctx = canvas.getContext("2d")!;

		// Fill transparent pixels
		ctx.fillStyle = '#000';
		ctx.fillRect(0, 0, size, size);

		// Уменьшение по шагам: помогает достигать нормального качества даже при билинейной интерполяции
		// https://stackoverflow.com/questions/17861447/html5-canvas-drawimage-how-to-apply-antialiasing
		// Сначала уменьшаем картинку в 2 раза
		ctx.drawImage(image, 0, 0, width, height, 0, 0, width / 2, height / 2);
		// Ещё раз уменьшаем картинку в 2 раза
		ctx.drawImage(canvas, 0, 0, width / 2, height / 2, 0, 0, width / 4, height / 4);
		// Потом уменьшаем уменьшенную в 4 раза копию уже до требуемого размера
		ctx.drawImage(canvas, 0, 0, width / 4, height / 4, 0, 0, size, size);

		this.hash = ctx.getImageData(0, 0, size, size);
	}

	isSimilar(other: ImageHash) {
		const dataX = this.hash;
		const dataY = other.hash;

		// Обе картинки конвертируются в чб, чтобы алгоритм работал ожидаемым образом и для реальных чб картинок, и для цветных
		// Максимальное отличие значения пикселя, чтобы пиксели считались разными
		// Тут нельзя ставить слишком маленькое значение -- у разных буру aspect ratio одного и того же превью может отличаться на 1-2 пикселя,
		// и при уменьшении картинка немного поедет
		const threshold = 12;
		// Максимальное количество % различающихся пикселей, чтобы картинки считались разными
		const maxDifferentPixelsPercent = 10;

		let differentPixels = 0;

		for (let x = 0; x < size; x++) {
			for (let y = 0; y < size; y++) {
				const i = (y * size + x) * 4;

				const gx = grayscale(dataX, i);
				const gy = grayscale(dataY, i);

				if (Math.abs(gx - gy) > threshold) {
					differentPixels++;
				}
			}
		}

		const percent = differentPixels / (size * size) * 100;

		//if (percent < 50) {
		//	console.log(percent + '%');
		//}

		return percent < maxDifferentPixelsPercent;
	}

}

function grayscale(data: ImageData, i: number) {
	return data.data[i] * 0.2989 + data.data[i + 1] * 0.5870 + data.data[i + 2] * 0.1140;
}