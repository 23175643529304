import React, { Component } from 'react';

import { API, LoginResult } from '../api/API';
import { TokenStorage } from '../api/TokenStorage';

import './LoginForm.css';

class LoginForm extends Component<{onLoggedIn: () => void}> {

	state = {
		loading: true,
		login: '',
		password: ''
	}

	render() {
		return <div className="loginForm">
			<form>
				<h1>Metabooru</h1>
				<div><input placeholder="Login" value={this.state.login} onChange={e => this.setState({login: e.target.value})}/></div>
				<div><input placeholder="Password" value={this.state.password} onChange={e => this.setState({password: e.target.value})} type="password"/></div>
				<div><button onClick={e => this.onLogin(e)}>Log in</button></div>
			</form>
		</div>;
	}

	private onLogin(e: any) {
		e.preventDefault();

		API.doRequestAlertError<LoginResult>(e, c => API.login(this.state.login, this.state.password, c), response => {
			if (response.error !== undefined) {
				alert(response.error);
				return;
			}

			TokenStorage.setToken(response.token!);

			this.props.onLoggedIn();
		});
	}

}

export default LoginForm;
