export function registerTouchListener(callback: (direction: number) => void) {
	let xDown = null as number | null;
	let yDown = null as number | null;

	const getTouches = (evt: any) => {
		return evt.touches || evt.originalEvent.touches;
	}                                                     

	const handleTouchStart = (evt: any) => {
		const firstTouch = getTouches(evt)[0];                                      
		xDown = firstTouch.clientX;                                      
		yDown = firstTouch.clientY;                                      
	};                                                

	const handleTouchMove = (evt: any) => {
		if (xDown === null || yDown === null) {
			return;
		}

		let xUp = evt.touches[0].clientX;                                    
		let yUp = evt.touches[0].clientY;

		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			let diff = 0;

			if (xDiff > 0) {
				diff = -1;
			} else {
				diff = 1;
			}

			callback(diff);
		}

		xDown = null;
		yDown = null;                                             
	};

	document.addEventListener('touchstart', handleTouchStart, false);        
	document.addEventListener('touchmove', handleTouchMove, false);
}