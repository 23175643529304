import { Post } from "../api/API";
import { ImageHash } from "./group/ImageHash";

export class DisplayPost {

	post: Post;
	
	// Если пост был удалён из сохранённых
	deletedFromSaved = false;

	hash = null as ImageHash | null;
	
	// Выключает минимизацию, даже если пост просмотрен
	doNotMinimize = false;

	constructor(post: Post) {
		this.post = post;
	}

}