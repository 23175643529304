export class TokenStorage {

	static getToken(): string | undefined {
		const t = localStorage.getItem('token');

		return t === null ? undefined : t;
	}

	static setToken(token: string): void {
		localStorage.setItem('token', token);
	}

	static clearToken(): void {
		localStorage.removeItem('token');
	}

}