import { DisplayPost } from "../DisplayPost";
import { ImageHash } from "./ImageHash";

class Group {

	hash: ImageHash;
	posts = [] as DisplayPost[];

	constructor(hash: ImageHash) {
		this.hash = hash;
	}

	copy(): Group {
		const group = new Group(this.hash);
		group.posts = [...this.posts];
		return group;
	}

}

export class GroupList {

	private posts = [] as DisplayPost[];
	private groups = [] as Group[];
	private groupsByKey = {} as {[key: string]: Group};

	private addPost(post: DisplayPost): void {
		if (post.hash === null) {
			throw new Error('Hash not set on the post');
		}

		if (this.hasPost(post)) {
			// Уже есть в одной из групп
			return;
		}

		this.posts.push(post);
		
		// Есть 2 варианта проверки на содержание поста в группе:
		// - сравнение с хешом первого поста в группе
		// - сравнение со всеми хешами в группе -- если есть хоть одно совпадение, то пост в группе
		// В старом агрегаторе юзался второй способ, тут -- первый
		// Пока непонятно, какой из них лучше. В теории второй способ может создавать бесконечные группы
		// из постов, которые сильно различаются, но "похожи" через цепочку промежуточных 

		for (const group of this.groups) {
			if (group.hash.isSimilar(post.hash)) {
				// Добавляем в существующую
				group.posts.push(post);
				this.groupsByKey[key(post)] = group;
				return;
			}
		}

		const group = new Group(post.hash);
		group.posts.push(post);
		this.groups.push(group);
		this.groupsByKey[key(post)] = group;
	}

	getGroup(post: DisplayPost): DisplayPost[] {
		const group = this.groupsByKey[key(post)];
		return group === undefined ? [] : group.posts;
	}

	getGroupSize(post: DisplayPost): number {
		return this.getGroup(post).length;
	}

	getPosts(): DisplayPost[] {
		return this.posts;
	}

	hasPost(post: DisplayPost): boolean {
		return this.getGroupSize(post) > 0;
	}

	copyAndAdd(post: DisplayPost): GroupList {
		const list = new GroupList();
		list.posts = [...this.posts];
		list.groups = this.groups.map(x => x.copy());
		list.groups.forEach(g => g.posts.forEach(p => list.groupsByKey[key(p)] = g));
		list.addPost(post);
		return list;
	}

}

function key(post: DisplayPost) {
	return post.post.booru + '/' + post.post.id;
}